@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (prefers-color-scheme: dark) {
  .day.dark-mode {
    background: #111111;
    color: #ffffff;
  }
  .night.dark-mode {
    background: #111111;
    color: #ffffff;
  }
  .navbar-link.dark-mode {
    background-color: #5e86bf;
  }
  .navbar-link.dark-mode:hover {
    color: #5e86bf;
    background-color: #ffffff;
  }
  .headings.dark-mode {
    background-color: transparent;
  }
  .content-text.dark-mode {
    background-color: #252525;
  }
  .about-box.dark-mode {
    background-color: #252525;
  }
  .skills-box.dark-mode {
    background-color: #5e86bf;
  }
  .skill-list.dark-mode {
    background-color: #5e86bf;
  }
  .headings.dark-mode {
    background: transparent;
  }
  .content-text.dark-mode {
    background-color: transparent;
  }
  .arrow.dark-mode {
    border: solid #111111;
    margin: -2em 0 0 5em;
    border-width: 0px 0px 5px 5px;
    display: inline-block;
    padding: 1.5em;
    box-shadow: -2px 3px #111111;
    border-radius: 5%;
    -webkit-animation: sdb05 1.5s infinite;
            animation: sdb05 1.5s infinite;
    box-sizing: border-box;
  }
  .port-title-card.dark-mode {
    background: transparent;
  }
  .port-title-text.dark-mode {
    background: transparent;
  }
  .scrolled.dark-mode {
    background-color: #111111;
  }
  .res-link.dark-mode {
    color: #ffffff;
  }
  .language-list.dark-mode {
    padding: 0;
    text-decoration: none;
    margin: 0 auto;
    border-top: 1px #ffffff solid;
  }
  .social-hover.dark-mode {
    text-decoration: none;
    color: #5e86bf;
    transition: all 0.4s;
    font-weight: 900;
  }
  
  .social-hover.dark-mode:hover {
    color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  .day.light-mode {
    background: white;
    color: #111111;
  }
  .night.light-mode {
    background: #ffffff;
    color: #111111;
  }
  .navbar-link.light-mode {
    background-color: #86AEE7;
  }
  .navbar-link:hover {
    color: #86AEE7;
    background-color: #111111;
  }
  .headings.light-mode {
    background-color: transparent;
  }
  .content-text.light-mode {
    background-color: #dadada;
  }
  .about-box.light-mode {
    background-color: #dadada;
  }
  .about-box.light-mode:hover {
    -webkit-filter: brightness(95%);
            filter: brightness(95%);
  }
  .skills-box.light-mode {
    background-color: #86AEE7;
  }
  .skill-list.light-mode {
    background-color: #86AEE7;
  }
  .content-text.light-mode {
    background-color: transparent;
  }
  .arrow.light-mode {
    border: solid #ffffff;
    margin: -2em 0 0 5em;
    border-width: 0px 0px 5px 5px;
    display: inline-block;
    padding: 1.5em;
    box-shadow: -2px 3px #111111;
    border-radius: 5%;
    -webkit-animation: sdb05 1.5s infinite;
            animation: sdb05 1.5s infinite;
    box-sizing: border-box;
  }
  .port-title-card.light-mode {
    background: transparent;
  }
  .port-title-text.light-mode {
    background: transparent;
  }
  .scrolled.light-mode {
    background-color: #ffffff;
  }
  .res-link.light-mode {
    background-color: #86AEE7;
    color: #111111;
  }
  .res-link.light-mode:hover {
    background-color: #111111;
  }
  .language-list.light-mode {
    padding: 0;
    text-decoration: none;
    margin: 0 auto;
    border-top: 1px #111111 solid;
  }
  .social-hover.light-mode {
    text-decoration: none;
    color: #86AEE7;
    transition: all 0.4s;
    font-weight: 900;
  }
  
  .social-hover.light-mode:hover {
    color: #111111;
  }
  .landing-bottom.light-mode {
    -webkit-filter: brightness(150%);
            filter: brightness(150%);
  }
  .contact-card.light-mode {
    -webkit-filter: brightness(75%);
            filter: brightness(75%);
  }
}

html {
  scroll-behavior: smooth;
}

body {
  background: #111111;
}

.language-list {
  padding: 0;
  text-decoration: none;
  margin: 0 auto;
  border-top: 1px #ffffff solid;
}

li {
  list-style: none;
}

.container {
  display: grid;
  align-self: center;
  height: 100%;
}

.title-card {
  background: #111111;
  margin: 5%;
  padding: 0 0 5% 5%;
  opacity: 0.75;
  border-radius: 2% 6% 40%;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition: all 0.5s;
  transition-property: box-shadow, transform;
}

.title-card:hover {
  opacity: 0.85;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
}

a {
  text-decoration: none;
  color: #5e86bf;
  transition: all 0.5s;
  font-weight: 900;
}

a:hover {
  color: #ffffff;
}

.container-bottom {
  display: grid;
  grid-template-columns: 50% 50%;
  align-self: center;
}

.landing-bottom {
  background-image: url(/static/media/coast.0183cc40.jpg);
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.content-bottom {
  background-color: #111111;
  padding: 0 10% 5%;
}

.port-title-card {
  background: #5e86bf;
  margin: 0;
  padding: 1.1%;
  border-radius: 0.4em 0 0 0.4em;
}

.port-title-text {
  display: inline-block;
  width: 21%;
  color: #ffffff;
  font-size: 2.25vw;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin: 0 auto;
}

.port-body-card {
  color: #ffffff;
  margin-bottom: 2vh;
}

.port-icons {
  padding-right: 0.7em;
  margin-top: -1em;
}

.navbar {
  background-color: transparent;
  padding: 1% 5% ;
  margin: 0 auto;
  position: absolute;
  top: 0;
  width: 90%;
  overflow: visible;
  transition: all .5s ease-in;
  z-index: 2;

}

.scrolled {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111111;
}

.navbar-link {
  color: #ffffff;
  font-size: 1.6em;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  letter-spacing: 0.12em;
  margin: 0em 0.5em;
  padding: 5px 14px;
  background-color: #5e86bf;
  box-shadow: 1px 1px 3px #000000;
  float: right;
  border-radius: 0.1em;
}

.navbrand {
  background: #ffffff;
  text-align: right;
  color: #111111;
  padding: 0.15em 0.4em;
  border-radius: 0.1em;
  opacity: 0;
}

.navbrand-text {
  color: #000;
  font-size: 1.5em;
  font-family: 'PT Mono', monospace;
  font-weight: 200;
  margin: 0 auto;
  text-align: center;
  letter-spacing: -0.1em;
}

.navbar-button {
  display: none;
}

.landing {
  display: block;
  position: relative;
  background-image: url(/static/media/BGalt.e27729f1.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 60%;
}

.landing-card {
  position: absolute;
  top: 18vw;
  left: 5vw;
  background: #111111;
  min-width: 25%;
  min-height: 25%;
  padding: 2vw;
  opacity: 0.85;
  border-radius: 1.5em;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  transition: all 1s;
  transition-property: box-shadow, transform;
}

.landing-card:hover {
  opacity: 0.90;
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
}

.fullname {
  color: #ffffff;
  font-size: 4vw;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  margin: 0.3em 0 0.5em;
}

.role {
  color: #ffffff;
  font-size: 2vw;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  margin: 0 auto;
}

.location-text {
  color: #ffffff;
  font-family: 'PT Mono', sans-serif;
  font-size: 1.25vw;
  line-height: 1em;
  font-weight: 400;
  letter-spacing: 0.15em;
  visibility: hidden;
}

.map-icon {
  margin-right: 1em;
}

.arrow {
  border: solid #ffffff;
  margin: -2em 0 0 5em;
  border-width: 0px 0px 5px 5px;
  display: inline-block;
  padding: 1.5em;
  box-shadow: -2px 3px #111111;
  border-radius: 5%;
  -webkit-animation: sdb05 1.5s infinite;
          animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

.scroller {
  position: absolute;
  top: 75%;
  left: 42%;
}

.scroller-text {
  color: #ffffff;
  font-size: 1.5em;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  margin-top: 1em;
  letter-spacing: .2em;
  text-shadow: 1px 2px 5px #111111, 1px 1px 0px #111111, -1px -2px 2px #111111 ;
  transition: all 0.5s;
}

.scroller-text:hover {
  transform: scale(1.1);
}

.headings {
  color: #ffffff;
  font-size: 2em;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 200;
  margin-top: 1em;
  letter-spacing: .2em;
}

.content {
  background-color: #111111;
  padding: 1% 5%;
  margin: 0 auto;
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  padding: 3%;
  grid-gap: 1em;
}

.about-box {
  background-color: #252525;
  border-radius: 0.5em;
  margin-left: 0.5em;
  transition: all 0.5s;
}

.skills-box {
  border-radius: 0.5em;
  background-color: #5e86bf;
  margin: unset;
  transition: all 0.5s;
}

.about-box:hover, .skills-box:hover {
  -webkit-filter: brightness(120%);
          filter: brightness(120%);
}

.content-text {
  color: #ffffff;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.1em;
  line-height: 3em;
  padding: 1%;
  margin: 0;
  font-weight: 400;
}

.span-border {
  display: block;
  width: 100%;
  border-bottom: 1px #ffffff solid;
}

.skill-list {
  display: flex;
  margin-top: 0 auto;
  justify-content: space-between;
  justify-items: left;
}

.heavy-text {
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 3em;
}

.res-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.res-link {
  color: #ffffff;
  background-color: #5e86bf;
  letter-spacing: 0.3em;
  font-size: 1.5em;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  padding: 0.5em;
  border-radius: 0.2em;
  line-height: 0.6em;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  flex-direction: column;
}

.res-link:hover {
  color: #5e86bf;
  background-color: #ffffff;
}

.portfolio-grid {
  display: block;
}

.port-one, .port-two, .port-three {
  transition: all 0.5s;
  border: 2px #5e86bf solid;
  border-radius: 0.5em;
  background: linear-gradient(to left, #252525 50%, #5e86bf 50%);
  background-size: 200% 100%;
  background-position:right bottom;
}

.port-one:hover, .port-two:hover, .port-three:hover {
  transform: scale(1.01);
  -webkit-filter: brightness(110%);
          filter: brightness(110%);
  background-position: left bottom;
  transition: all 0.25s ease;
}

.contact-card {
  background: #111111;
  margin: 5%;
  padding: 2% 0 1% 0;
  opacity: 0.85;
  border-radius: 1em;
}

.contact-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
}

.contact-heading {
  color: #ffffff;
  font-size: 1.7em;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 300;
  text-align: center;
}

.social {
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
}

.phone-social {
  display: none;
}

.avatar {
  border-radius: 50%;
  width: 55%;
  margin-left: 3em;
}

.icon-spacing {
  margin: 0 1vw;
}

.contact-one {
  grid-column: 1;
  grid-row: 1;
}

.contact-two {
  grid-column: 1;
  grid-row: 2;
}

.contact-three {
  grid-column: 2;
  grid-row: 1/3;
}

.social-text {
  font-size: 1em;
  font-weight: 400;
  color: #ffffff;
  box-shadow: inset 0px 0px 0px 1000px #5e86bf;
  margin: 0.25em;
}

@media screen and (max-width: 798px) {
  .navbar a:not(:first-child) {display: none;}
  .navbar {background-color: #111111;}
  .navbar p.navbar-button {
    float: right;
    display: block;
    color: #5e86bf;
    margin: 0.5em;
  }
  .landing {
    padding-top: 85%;
  }
  .landing-card {
    background-color: transparent;
    margin-top: 1em;
  }
  .fullname {
    font-size: 2.5em;
    padding: 0.20em;
  }
  .role {
    font-size: 1.5em;
    padding: 0.15em;
    letter-spacing: 0.15em;
  }
  .location-text {
    font-size: 0.9em;
    padding: 0.4em;
    letter-spacing: 0.05em;
  }
  .navbrand-text {
    font-size: 1em;
    padding: 0.5em;
    position: absolute;
    top: 0.25em;
  }
  .res-link {
    font-size: 1.5em;
  }
  .norm-btn {
    line-height: 3em;
    margin: 0 auto;
  }
  .port-title-text {
    font-size: 1.5em;
  }
  .scroller {
    display: none;
  }
  .avatar {
    margin: 1em 0 0 1em;
    width: 90%;
  }
  svg {
    width: 1em;
    height: 1em;
  }
  .map-icon {
    width: 1em;
    height: 1em;
  }
  .port-title-text {
    width: 100%;
    font-size: 2em;
  }
  .content-text {
    font-size: 1em;
    line-height: 2em;
  }
  .content-grid {
    grid-template-columns: 1fr;
  }
  .skills-box {
    margin-left: 0.5em;
  }
  .social {
    display: none;
  }
  .phone-social {
    display: inline-block;
  }
  .contact-content {
    display: flex;
  }
  .contact-one {
    padding-left: 1em;
  }
  .contact-three {
    padding-right: 1em;
  }
  .res-link {
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 798px) {
  .navbar.responsive {
    position: absolute;
    z-index: 9999;
  }
  .navbar.responsive p.navbar-button {
    position: absolute;
    right: 1.5em;
    top: 0.3em;
  }
  .navbar.responsive a {
    float: none;
    display: block;
    text-align: center;
    z-index: 9997;
  }
  .navbar-link {
    background-color: transparent;
    margin-top: 1.6em;
    z-index: 9998;
  }
}
